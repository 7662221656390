<template>
    <div>
      <vx-card class="tabulator_card">
        <vs-row class="mb-3">
          <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start">
            <treeselect
              v-model="selected_column"
              :multiple="true"
              style="z-index:10000"
              :options="treeDataHide"
              placeholder="Hide Column"
            />
          </vs-col>
          <vs-col
            vs-w="3"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-button color="#0044BA" size="small" @click="HideColumn">
              Hide - Unhide Columns
            </vs-button>
          </vs-col>
        </vs-row>
        <div ref="table3" id="example-table-theme"></div>
      </vx-card>
    </div>
  </template>
  
  <script>
  import { TabulatorFull as Tabulator } from "tabulator-tables";
  import Treeselect from "@riophae/vue-treeselect";
  import EventBus from "../components/eventbus";
  import moment from "moment";
  export default {
    components: {
      Treeselect,
    },
    props: ["enrollment_details","ActiveNavBarTab"],
    data(){
      return {
        selected_column: [],
        treeDataHide:[
          // {
          //   id:"company",
          //   label:"Company"
          // },
          // {
          //   id:"education",
          //   label:"Education"
          // },
          {
            id:"level",
            label:"M Level"
          },
          {
            id:"iiml_level",
            label:"U Level"
          },
          {
            id:"course",
            label:"Course"
          },
          // {
          //   id:"batch",
          //   label:"Batch"
          // },
          {
            id:"enrollment_date",
            label:"Enrollment Date"
          },
          {
            id:"next_call",
            label:"Next Call Date"
          },
          {
            id:"last_call",
            label:"Last Call Date"
          },
          {
            id: "sr_next_call",
            label: "SR Next Call Date",
          },
          {
            id: "sr_last_call",
            label: "SR Last Call Date",
          },
          {
            id:"requested_spoc_first_name",
            label:"USP Re-Enrollment Requested By"
          },
          {
            id:"assigned_to_name",
            label:"Enrolled By Spoc"
          },
          {
            id:"spoc_name",
            label:"CM SPOC"
          },
          {
            id:"sr_name",
            label:"SR SPOC"
          },
          // {
          //   id:"city",
          //   label:"City"
          // },
          // {
          //   id:"city_classification",
          //   label:"City Classification"
          // },
          // {
          //   id:"gm_name",
          //   label:"GM"
          // },
          // {
          //   id:"batch_start_date",
          //   label:"Batch Start Date"
          // },
          // {
          //   id:"created_at",
          //   label:"Lead Created Date"
          // },
          // {
          //   id:"actual_cost",
          //   label:"Actual Cost"
          // },
          {
            id:"agreed_cost",
            label:"Agreed Cost"
          },
          // {
          //   id:"discount_amount",
          //   label:"Discount Amount"
          // },
          // {
          //   id:"reason_for_discount",
          //   label:"Comments"
          // },
          // {
          //   id: "approved_by",
          //   label: "Approved By",
          // },
        ],
      }
    },
    mounted(){
      this.getEnrollmentDetails();
      for (let i = 0; i < this.enrollment_details.length; i++) {
        const element = this.enrollment_details[i];
        // if (element.enrollment_date != null) {
        //   element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
        // }
        if (element.batch_start_date != null) {
          element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
        }
        if (element.enrollment_date != null) {
          element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
        }
        if (element.last_call !== null) {
          element.last_call = moment
            .unix(element.last_call)
            .format("DD-MMM-YYYY");
        }
        if (element.next_call !== null) {
          element.next_call = moment
            .unix(element.next_call)
            .format("DD-MMM-YYYY");
        }
        if (element.sr_next_call !== null) {
          element.sr_next_call = moment
            .unix(element.sr_next_call)
            .format("DD-MMM-YYYY");
        }
        if (element.sr_last_call !== null) {
          element.sr_last_call = moment
            .unix(element.sr_last_call)
            .format("DD-MMM-YYYY");
        }
        let image_path = "";
        if(this.ActiveNavBarTab == "approved"){
          image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
        }else if(this.ActiveNavBarTab == "rejected"){
          image_path = "https://cdn-icons-png.flaticon.com/512/5978/5978441.png"
        }else{
          image_path = "https://img.icons8.com/color/24/000000/error.png"
        }
        Object.assign(element, { approved:  image_path});
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      }
    },
    watch:{
      enrollment_details(val){
        this.enrollment_details = val;
        this.getEnrollmentDetails();
      for (let i = 0; i < this.enrollment_details.length; i++) {
        const element = this.enrollment_details[i];
        // if (element.enrollment_date != null) {
        //   element.enrollment_date = moment.unix(element.enrollment_date)
        //     .format("DD-MMM-YYYY");
        // }
        if (element.batch_start_date != null) {
          element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
        }
        if (element.enrollment_date != null) {
          element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
        }
        if (element.last_call !== null) {
          element.last_call = moment
            .unix(element.last_call)
            .format("DD-MMM-YYYY");
        }
        if (element.next_call !== null) {
          element.next_call = moment
            .unix(element.next_call)
            .format("DD-MMM-YYYY");
        }
        if (element.sr_next_call !== null) {
          element.sr_next_call = moment
            .unix(element.sr_next_call)
            .format("DD-MMM-YYYY");
        }
        if (element.sr_last_call !== null) {
          element.sr_last_call = moment
            .unix(element.sr_last_call)
            .format("DD-MMM-YYYY");
        }
        let image_path = "";
        if(this.ActiveNavBarTab == "approved"){
          image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
        }else if(this.ActiveNavBarTab == "rejected"){
          image_path = "https://cdn-icons-png.flaticon.com/512/5978/5978441.png"
        }else{
          image_path = "https://img.icons8.com/color/24/000000/error.png"
        }
        Object.assign(element, { approved:  image_path});
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      }
      },
      ActiveNavBarTab(val){
        this.ActiveNavBarTab = val;
        console.log("ActiveNavBarTab",this.ActiveNavBarTab);
      }
    },
    methods:{
      getEnrollmentDetails(){
        this.tabulator = new Tabulator(this.$refs.table3, {
          maxHeight: "80vh",
          data: this.enrollment_details,
          rowFormatter: (function(row) {
            var evenColor = "#F5F6FA";
            var oddColor = "#FFF";
            row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
            }),
          columns: [
            {
              title: "Can-ID",
              field: "identity",
              frozen: true,
              sorter: "alphanum",
            },
            {
              title: "Candidate Name",
              field: "person_name",
              frozen: true,
            },
            // {
            //   title: "Company",
            //   field: "company",
            // },
            // {
            //   title: "Education",
            //   field: "education",
            // },
            {
              title: "M Level",
              field: "level",
              sorter: "alphanum",
            },
            {
              title: "U Level",
              field: "iiml_level",
              sorter: "alphanum",
            },
            {
              title: "Course",
              field: "course",
            },
            // {
            //   title: "Batch",
            //   field: "batch",
            // },
            {
              title: "Enrollment Date",
              field: "enrollment_date",
            },
            {
              title: "Next Call Date",
              field: "next_call",
            },
            {
              title: "Last Call Date",
              field: "last_call",
            },
            {
              title: "SR Next Call Date",
              field: "sr_next_call",
            },
            {
              title: "SR Last Call Date",
              field: "sr_last_call",
            },
            {
              title: "USP Re-Enrollment Requested By",
              field: "requested_spoc_first_name",
              // field: 'name',
                // mutator: (data) => data.requested_spoc_last_name + ', ' + data.requested_spoc_first_name,
                // headerFilter: 'input',
                // headerFilterFunc: (headerValue, rowData) => {
                //   let filterValue = headerValue.toUpperCase()
                //   let requested_spoc_first_name = rowData.requested_spoc_first_name.toUpperCase()
                //   let requested_spoc_last_name = rowData.requested_spoc_last_name.toUpperCase()

                //   return requested_spoc_first_name.includes(filterValue) || requested_spoc_last_name.includes(filterValue)
                // }

                // mutator:function(data){
                //   return data.requested_spoc_first_name + " - " + data.requested_spoc_last_name;
                // },
            },
            {
              title: "Enrolled By Spoc",
              field: "assigned_to_name",
            },
            {
              title: "CM SPOC",
              field: "spoc_name",
            },
            {
              title: "SR SPOC",
              field: "sr_name",
            },
            // {
            //   title: "City",
            //   field: "city",
            // },
            // {
            //   title: "City Classification",
            //   field: "city_classification",
            // },
            // {
            //   title: "GM",
            //   field: "gm_name",
            // },
            // {
            //   title: "Batch Start Date",
            //   field: "batch_start_date",
            // },
            // {
            //   title: "Actual Cost",
            //   field: "actual_cost",
            // },
            {
              title: "Agreed Cost",
              field: "agreed_cost",
            },
            // {
            //   title: "Discount Amount",
            //   field: "discount_amount",
            // },
            // {
            //   title: "Comments",
            //   field: "reason_for_discount",
            // },
            // {
            //   title: "Approved By",
            //   field: "approved_by",
            // },
            {
              title: "Approved",
              field: "approved",
              headerSort: false,
              formatter:"image",
              hozAlign:"center",
              formatterParams:{
                height:"25px",
                width:"25px",
              },
              cellClick: this.openEnrollmentApproval,
            },
            {
              title: "About",
              field: "info",
              frozen: true,
              headerSort: false,
              formatter:"image",
              hozAlign:"center",
              formatterParams:{
                height:"25px",
                width:"25px",
              },
              cellClick: this.getId,
            },
          ],
        });
      },
      getId(e, cell) {
        console.log(e, cell._cell.row.data.mwb_id);
        this.openBigPopup(cell._cell.row.data.mwb_id);
      },
      openEnrollmentApproval(e,cell) {
        console.log(e, cell._cell.row.data.mwb_id);
        EventBus.$emit("open-enrollment-approvala-popup", cell._cell.row.data);
      },
      HideColumn(){
        this.treeDataHide.forEach((treeData) => {
             this.tabulator.showColumn(treeData.id)
        });
        if(this.selected_column.length > 0){
          this.selected_column.forEach((selected_item)=>{
            this.treeDataHide.forEach((item) =>{
                if(item.id == selected_item){
                  this.tabulator.hideColumn(item.id)
                }
              })
            })
        }
      },
    }
  };
  </script>
  
  <style>
  .tabulator_card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
  }
  .tabulator {
    background-color: #f2f2f2;
  }
  
  .tabulator .tabulator-header {
    background-color: #f2f2f2;
    /* padding-block: 5px; */
  }
  
  .tabulator .tabulator-row:nth-child(odd) {
    background-color: #f2f2f2;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
  }
  
  .tabulator .tabulator-row:nth-child(even) {
    background-color: #e4e1e1;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
  }
  
  .tabulator .tabulator-header .tabulator-col {
    text-align: center;
  }
  
  .tabulator-calcs-bottom .tabulator-cell {
    background-color: rgb(0, 0, 0);
    color: #f2f2f2 !important;
  } 
  </style>